import { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AE } from 'country-flag-icons/react/3x2';
import axios from 'axios';
import {clsx} from 'clsx';

import Logo from '../assets/images/logo.svg';
import WatchIcon from '../assets/images/watch-icon.png';
import AppleIcon from '../assets/images/apple-icon.svg';
import PlayStoreIcon from '../assets/images/play-store-icon.svg';
import GarageIcon from '../assets/images/garage-icon.svg';
import MeetupIcon from '../assets/images/meetup-icon.svg';
import DriveIcon from '../assets/images/drive-navigation-icon.png';
import CommunityIcon from '../assets/images/community-icon.svg';
import ForumIcon from '../assets/images/forum-icon.png';
import TippingIcon from '../assets/images/tipping-icon.svg';
import MeetupImage from '../assets/images/meetup.png';
import CommunityImage from '../assets/images/join-community.png';
import DriveNavigation from '../assets/images/drive-navigation.png';
import TippingImage from '../assets/images/tipping.png';
import GearToken from '../assets/images/gear-token.png';
import CarCafe from '../assets/images/car-cafe.png';

import Card1 from '../assets/images/card1.png';
import Card2 from '../assets/images/card2.png';
import Card3 from '../assets/images/card3.png';
import Card4 from '../assets/images/card4.png';

import LeftImage1 from '../assets/images/left-slider-1.png';
import LeftImage2 from '../assets/images/left-slider-2.png';
import LeftImage3 from '../assets/images/left-slider-3.png';
import LeftImage4 from '../assets/images/left-slider-4.png';
import LeftImage5 from '../assets/images/left-slider-5.png';
import LeftImage6 from '../assets/images/left-slider-6.png';
import LeftImage7 from '../assets/images/left-slider-7.png';
import LeftImage8 from '../assets/images/left-slider-8.png';

import RightImage1 from '../assets/images/right-slider-1.png';
import RightImage2 from '../assets/images/right-slider-2.png';
import RightImage3 from '../assets/images/right-slider-3.png';
import RightImage4 from '../assets/images/right-slider-4.png';
import RightImage5 from '../assets/images/right-slider-5.png';
import RightImage6 from '../assets/images/right-slider-6.png';
import RightImage7 from '../assets/images/right-slider-7.png';
import RightImage8 from '../assets/images/right-slider-8.png';
import RightImage9 from '../assets/images/right-slider-9.png';

import UserAvatar1 from '../assets/images/user-avatar-1.png';
import UserAvatar2 from '../assets/images/user-avatar-2.png';
import UserAvatar3 from '../assets/images/user-avatar-3.png';
import UserAvatar4 from '../assets/images/user-avatar-4.png';
import UserAvatar5 from '../assets/images/user-avatar-5.png';
import UserAvatar6 from '../assets/images/user-avatar-6.png';
import UserAvatar7 from '../assets/images/user-avatar-7.png';

import config from '../config';

function Home() {
  const emailRef = useRef(null);
  const [isSm, setIsSm] = useState(false);
  const [currentLink, setCurrentLink] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const subscribeEmail = () => {
    const email = emailRef.current.value;
    const toastOptions = { position: 'top-right', autoClose: true, closeOnClick: true };

    if (!validateEmail(email)) {
      toast.warn('Please input valid email.', toastOptions);
      return;
    }

    axios
      .post(config.serverUrl + '/v1/auth/register', { email })
      .then((response) => {
        toast.success('Successfully subsribed.', toastOptions);
        updateUserCount();
      })
      .catch((error) => {
        const { response } = error;

        if (response.data.code == 400 && response.data.message == 'Email already taken') {
          toast.error('Email already subscribed.', toastOptions);
        } else {
          toast.error('Failed to subscribe');
        }
      });
  };

  const updateUserCount = () => {
    axios.get(config.serverUrl + '/v1/users/count')
    .then((response) => {
      setUserCount(response.data.count)
    })
  }

  const updateIsSm = () => {
    let value = window.matchMedia('(max-width: 800px)');
    setIsSm(value.matches);
  };

  const checkActiveLink = (e) => {
    const aboutElement = document.querySelector('#about');
    const featureElement = document.querySelector('#feature');
    const gearsElement = document.querySelector('#gears');
    const joinElement = document.querySelector('#join');
    const aboutY = aboutElement.getClientRects()[0].y;
    const featureY = featureElement.getClientRects()[0].y;
    const gearsY = gearsElement.getClientRects()[0].y;
    const joinY = joinElement.getClientRects()[0].y;
    const windowHeight = window.innerHeight;

    if (featureY > 100) {
      setCurrentLink(0);
    } else if (gearsY > 100) {
      setCurrentLink(1);
    } else if (joinY > 100) {
      setCurrentLink(2);
    } else {
      setCurrentLink(3)
    }
  }

  useEffect(() => {
    updateIsSm();
    checkActiveLink();
    updateUserCount();

    window.addEventListener('resize', updateIsSm);
    window.addEventListener('scroll', checkActiveLink);

    return () => {
      window.removeEventListener('resize', updateIsSm);
      window.removeEventListener('scroll', checkActiveLink);
    };
  }, []);

  return (
    <div className="App">
      <div className="menu-section">
        <div className="menu-wrapper">
          <div className="part1">
            <img className="menu-logo" src={Logo} />
            <div className="proud-title">Proudly made in UAE <AE width={20}></AE></div>
          </div>
          <div className="part2">
            <div className="link-wrapper">
              <a href="#about" className={clsx('link', currentLink == 0 && 'active')}>About</a>
              <a href="#feature" className={clsx('link', currentLink == 1 && 'active')}>Features</a>
              <a href="#gears" className={clsx('link', currentLink == 2 && 'active')}>GEARs</a>
            </div>
            <a href='#join' className="join-button">{!isSm ? 'Join the waiting list' : 'Join'}</a>
          </div>
        </div>
      </div>
      <div className="banner-wrapper" id="about">
        <div className="banner-container">
          <div className="banner-action">
            <div className="heading">
              <div className="item2">Earn.Meet.Drive</div>
              <div className="item3">The social media app for car enthusiasts</div>
            </div>
            <div className="button-wrapper">
              <a href='#join' className="join-button">Join the waiting list today!</a>
            </div>
            <div className="apps-wrapper">
              Coming soon on
              <button className="app-button">
                <img src={AppleIcon} />
                App Store
              </button>
              <button className="app-button">
                <img src={PlayStoreIcon} />
                Google Play
              </button>
            </div>
          </div>
          <div className="banner-image" />
          <img src={Card1} className='banner-card card1' />
          <img src={Card2} className='banner-card card2' />
          <div className='banner-left-card-container'>
            <img src={Card3} className='card3' />
            <img src={Card4} className='card4' />
          </div>
          <div className='floating-banner-text-wrapper'>
            <div className='floating-banner-text'>
              <span>BENZIFIBENZIFIBENZIFI</span>
              <span>BENZIFIBENZIFIBENZIFI</span>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrapper" id="feature">
        <p className="header-title">The only car app you will need</p>
        <div className="garage-section">
          <div className="title">
            <img src={GarageIcon} />
            <p className="section-header-title">Garage</p>
            <p className="section-header-cont">Show off your ride</p>
          </div>
          <div className="slider-wrapper-vertical">
            <div className="marquee-vertical">
              <div className="marquee__group">
                <img src={LeftImage1} />
                <img src={LeftImage2} />
                <img src={LeftImage3} />
                <img src={LeftImage4} />
                <img src={LeftImage5} />
                <img src={LeftImage6} />
                <img src={LeftImage7} />
                <img src={LeftImage8} />
              </div>
            </div>
            <div className="marquee-vertical-reverse">
              <div className="marquee__group">
                <img src={RightImage1} />
                <img src={RightImage2} />
                <img src={RightImage3} />
                <img src={RightImage4} />
                <img src={RightImage5} />
                <img src={RightImage6} />
                <img src={RightImage7} />
                <img src={RightImage8} />
                <img src={RightImage9} />
              </div>
            </div>
          </div>
        </div>
        <div className="split-section">
          <div className="inner-section">
            <div className="meetup-section">
              <div className="title">
                <img src={MeetupIcon} />
                <p className="section-header-title">Meet up</p>
              </div>
              <img src={MeetupImage} />
            </div>
            <div className="community-section">
              <div className="title">
                <img src={CommunityIcon} />
                <p className="section-header-title">Join the community</p>
              </div>
              <img src={CommunityImage} />
            </div>
          </div>
          <div className="inner-section">
            <div className="navi-section">
              <div className="title">
                <img src={DriveIcon} />
                <p className="section-header-title">Drive Navigation</p>
              </div>
              <img src={DriveNavigation} />
            </div>
          </div>
        </div>
        <div className="split-section">
          <div className="inner-section">
            <div className="forum-section">
              <div className="title">
                <img src={ForumIcon} />
                <p className="section-header-title">Forum</p>
              </div>
              <div className="slider-wrapper">
                <div className="marquee">
                  <div className="marquee__group">
                    <p className="forum-item">What is your favorite car brand and why?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">What is your favorite car brand and why?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                  </div>
                  <div aria-hidden="true" className="marquee__group">
                    <p className="forum-item">What is your favorite car brand and why?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">What is your favorite car brand and why?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                    <p className="forum-item">the best car for daily driving?</p>
                  </div>
                </div>
                <div className="marquee-reverse">
                  <div className="marquee__group">
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">best car for off-roading</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">best car for off-roading</p>
                  </div>
                  <div aria-hidden="true" className="marquee__group">
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">best car for off-roading</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">best car for off-roading</p>
                  </div>
                </div>
                <div className="marquee">
                  <div className="marquee__group">
                    <p className="forum-item">What is the most iconic car of all time?</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">What is the most iconic car of all time?</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                  </div>
                  <div aria-hidden="true" className="marquee__group">
                    <p className="forum-item">What is the most iconic car of all time?</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                    <p className="forum-item">What is the most iconic car of all time?</p>
                    <p className="forum-item">How has car culture changed over time?</p>
                    <p className="forum-item">What is the safest car on the market?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-section">
            <div className="tipping-section">
              <div className="title">
                <img src={TippingIcon} />
                <p className="section-header-title">Tipping</p>
                <p className="section-header-cont">Show your appreciate as tipping</p>
              </div>
              <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                <img src={TippingImage} />
              </div>
            </div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item">
            <img src={GarageIcon} />
            <p className="item-title">Garage</p>
            <p className="item-text">Show off your ride</p>
          </div>
          <div className="item">
            <img src={MeetupIcon} />
            <p className="item-title">Meet up</p>
          </div>
          <div className="item">
            <img src={CommunityIcon} />
            <p className="item-title">Join the community</p>
          </div>
          <div className="item">
            <img src={DriveIcon} />
            <p className="item-title">Drive Navigation</p>
          </div>
          <div className="item">
            <img src={ForumIcon} />
            <p className="item-title">Forum</p>
          </div>
          <div className="item tipping">
            <img src={TippingIcon} />
            <p className="item-title">Tipping</p>
            <p className="item-text">Show your appreciation and tip others</p>
          </div>
        </div>
      </div>
      <div className="get-started-section-wrapper" id="gears">
        <div className="get-started-wrapper">
          <div className="inner-section">
            <div className="gear-token-wrapper">
              <p className="title">REDEEM YOUR EARNED GEARS FOR...</p>
              <img src={GearToken} />
            </div>
          </div>
          <div className="inner-section">
            <div className="car-cafe-image">
              <img src={CarCafe} className="cafe-image-style" />
            </div>
            <div className="sub-section">
              <div className="panel color1">INSURANCE</div>
              <div className="panel color2">CARWASH</div>
            </div>
            <div className="sub-section">
              <div className="panel color3">BENZIN</div>
              <div className="panel color4">COFFEE</div>
            </div>
          </div>
        </div>
      </div>
      <div id="join" className="footer-section-wrapper">
        {/* <div className="owners"> */}
        {/* </div> */}
        <div className="footer-section">
          <div className="avatar-container avatar1">
            <img src={UserAvatar1} />
          </div>
          <div className="avatar-container avatar2">
            <img src={UserAvatar2} />
          </div>
          <div className="avatar-container avatar3">
            <img src={UserAvatar3} />
          </div>
          <div className="avatar-container avatar4">
            <img src={UserAvatar4} />
          </div>
          <div className="avatar-container avatar5">
            <img src={UserAvatar5} />
          </div>
          <div className="avatar-container avatar6">
            <img src={UserAvatar6} />
          </div>
          <div className="avatar-container avatar7">
            <img src={UserAvatar7} />
          </div>
          <div className="text1">{userCount} individuals</div>
          <p className="text2">have signed up to the waiting list</p>
          <div className="footer-subscription-wrapper">
            <input ref={emailRef} placeholder="Enter your email" className="email-input" />
            <button className="join-button" onClick={subscribeEmail}>
              Join now
            </button>
          </div>
        </div>
        <div className="footer-information">
          <p className="copyright">© Proudly made in UAE <AE width={20}/> by Benzifi</p>
          <div className="link-section">
            <a href="#" className="link-text">
              Privacy Policy
            </a>
            <a href="#" className="link-text">
              Terms of Use
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Home;
